import DashboardBreadcrumbComponent from "@/components/dashboard/layouts/DashboardBreadcrumbComponent";

const DashboardHomePage = () => {

    return (
        <div className="dashboardHomePage">

        </div>
    )
}

export default DashboardHomePage;