import { Outlet } from "react-router-dom";

const PasswordForgotLayout = () => {

    return (
        <>
            <Outlet />
        </>
    )
}

export default PasswordForgotLayout;