const DashboardErrorPage = () => {
    return (
        <div className="dashboardErrorPage">
            <div className="errorContainer">
                <div className="title">This page is not existing! </div>
            </div>
        </div>
    )
}

export default DashboardErrorPage;